import katex from "katex";
import MarkdownIt from "markdown-it";
import markdownItTexmath from "markdown-it-texmath";

export function ensureNewlinesForLatexBlocks(markdownText: string) {
  const regex = /(^|[^\\])(\\\[.*?\\\])/gs;
  const replacement = (_match: string, p1: string, p2: string) =>
    `${p1}\n\n${p2}\n\n`;
  return markdownText.replace(regex, replacement);
}

export function escapeLaTeX(text: string): string {
  return text.replace(/(?<!\\)\\\(/g, "\\\\(").replace(/(?<!\\)\\\)/g, "\\\\)");
}

export function unescapeLaTeX(text: string): string {
  return text.replace(/(?<!\\)\\\\\(/g, "\\(").replace(/(?<!\\)\\\\\)/g, "\\)");
}

export const md = new MarkdownIt({ html: true }).use(markdownItTexmath, {
  engine: katex,
  delimiters: "brackets",
});

export function stripHtml(html: string): string {
  if (typeof document === "undefined") return html;
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

/**
 * Checks if markdown text contains image references
 */
export function containsImages(markdownText: string): boolean {
  // Match standard markdown image syntax: ![alt text](url)
  const imageRegex = /!\[.*?\]\(.*?\)/;
  return imageRegex.test(markdownText);
}

/**
 * Extract all image references from markdown text
 * Returns an array of objects containing alt text and image URL
 */
export function extractImages(
  markdownText: string,
): Array<{ alt: string; url: string }> {
  // Match pattern: ![alt text](url)
  const imageRegex = /!\[(.*?)\]\((.*?)\)/g;
  const images: Array<{ alt: string; url: string }> = [];

  let match;
  while ((match = imageRegex.exec(markdownText)) !== null) {
    const [, alt, url] = match;
    images.push({
      alt: alt.trim(),
      url: url.trim(),
    });
  }

  return images;
}

/**
 * Removes all image references from markdown text
 */
export function stripImages(markdownText: string): string {
  // Replace image markdown pattern with empty string
  return markdownText.replace(/!\[.*?\]\(.*?\)/g, "");
}

/**
 * Replaces an image reference in markdown with the provided replacement text
 */
export function replaceImage(
  markdownText: string,
  imageUrl: string,
  replacementText: string,
): string {
  // Create regex that matches the specific image URL
  // Escape special regex characters in the URL
  const escapedUrl = imageUrl.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const imageRegex = new RegExp(`!\\[.*?\\]\\(${escapedUrl}\\)`, "g");

  return markdownText.replace(imageRegex, replacementText);
}

/**
 * Sanitizes text for Google Forms by replacing newlines with spaces
 * This prevents the "Displayed text cannot contain newlines" error
 */
export function sanitizeForGoogleForms(text: string): string {
  if (!text) return "";

  // Replace all types of newlines with spaces
  return text.replace(/\r?\n|\r/g, " ").trim();
}
