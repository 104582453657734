import { ActionIcon } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { TbPlayerStop, TbVolume } from "react-icons/tb";

interface TextToSpeechProps {
  text: string;
  languageCode: string;
  variant?: "default" | "button";
}

export function TextToSpeech({
  text,
  languageCode,
  variant = "default",
}: TextToSpeechProps) {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const utteranceRef = useRef<SpeechSynthesisUtterance | null>(null);

  const toggleSpeech = () => {
    if (typeof window === "undefined") return;

    const speechSynth = window.speechSynthesis;

    if (isSpeaking) {
      speechSynth.cancel();
      setIsSpeaking(false);
      utteranceRef.current = null;
      return;
    }

    speechSynth.cancel();

    const utterance = new SpeechSynthesisUtterance(text);
    utteranceRef.current = utterance;
    utterance.lang = languageCode;

    const voices = speechSynth.getVoices();
    const matchingVoice = voices.find((voice) =>
      voice.lang.startsWith(languageCode),
    );
    if (matchingVoice) {
      utterance.voice = matchingVoice;
    }

    utterance.onend = () => {
      setIsSpeaking(false);
      utteranceRef.current = null;
    };

    utterance.onerror = () => {
      setIsSpeaking(false);
      utteranceRef.current = null;
    };

    speechSynth.speak(utterance);
    setIsSpeaking(true);
  };

  useEffect(() => {
    return () => {
      if (typeof window !== "undefined" && utteranceRef.current) {
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  return (
    <ActionIcon
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        toggleSpeech();
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.stopPropagation();
          e.preventDefault();
          toggleSpeech();
        }
      }}
      variant="light"
      color="gray"
      size="xs"
      className={variant === "default" ? "absolute -bottom-2 -right-2" : ""}
      aria-label={isSpeaking ? "Stop reading" : "Read aloud"}
    >
      {isSpeaking ? <TbPlayerStop size={16} /> : <TbVolume size={16} />}
    </ActionIcon>
  );
}
