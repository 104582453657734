import DOMPurify from "isomorphic-dompurify";
import { useEffect, useState } from "react";
import { ClientOnly } from "remix-utils/client-only";
import { twMerge } from "tailwind-merge";
import {
  ensureNewlinesForLatexBlocks,
  md,
  stripHtml,
  unescapeLaTeX,
} from "~/utils/markdown/markdown.util";
import { TextToSpeech } from "../TextToSpeech/TextToSpeech";

interface CustomMarkdownProps {
  content?: string;
  languageCode?: string;
  variant?: "default" | "button";
  disableTextToSpeech?: boolean;
  maxHeight?: number;
  className?: string;
}

export default function MarkdownDisplay({
  content,
  languageCode = "en",
  variant = "default",
  disableTextToSpeech = false,
  maxHeight,
  className,
}: CustomMarkdownProps) {
  const [cleanText, setCleanText] = useState("");
  const renderedText = md.render(
    unescapeLaTeX(ensureNewlinesForLatexBlocks(content || "")),
  );

  useEffect(() => {
    setCleanText(stripHtml(renderedText));
  }, [renderedText]);

  if (!content) return "";

  return (
    <div
      className={
        variant === "default" ? "relative group" : "flex items-center gap-2"
      }
    >
      <div
        className={twMerge("flex-grow prose max-w-none", className)}
        style={{
          maxHeight: maxHeight ? `${maxHeight}px` : undefined,
          overflow: maxHeight ? "auto" : undefined,
        }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(renderedText) }}
      />
      {!disableTextToSpeech && (
        <ClientOnly fallback={null}>
          {() => (
            <TextToSpeech
              text={cleanText}
              languageCode={languageCode}
              variant={variant}
            />
          )}
        </ClientOnly>
      )}
    </div>
  );
}
